import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3815da4f = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _92197f92 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _44401983 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _1c29f71a = () => interopDefault(import('../pages/forgot_password.vue' /* webpackChunkName: "pages/forgot_password" */))
const _6ab06042 = () => interopDefault(import('../pages/introduce.vue' /* webpackChunkName: "pages/introduce" */))
const _5f735bfa = () => interopDefault(import('../pages/landing.vue' /* webpackChunkName: "pages/landing" */))
const _2c924f0c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _d630d4c2 = () => interopDefault(import('../pages/new-login.vue' /* webpackChunkName: "pages/new-login" */))
const _1874c9f8 = () => interopDefault(import('../pages/new-login-2.vue' /* webpackChunkName: "pages/new-login-2" */))
const _d7244360 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _a04b6110 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _3a876bb3 = () => interopDefault(import('../pages/setting.vue' /* webpackChunkName: "pages/setting" */))
const _60b08d76 = () => interopDefault(import('../pages/blogs/new.vue' /* webpackChunkName: "pages/blogs/new" */))
const _62eaabf5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _3815da4f,
    name: "blog"
  }, {
    path: "/blogs",
    component: _92197f92,
    name: "blogs"
  }, {
    path: "/contact",
    component: _44401983,
    name: "contact"
  }, {
    path: "/forgot_password",
    component: _1c29f71a,
    name: "forgot_password"
  }, {
    path: "/introduce",
    component: _6ab06042,
    name: "introduce"
  }, {
    path: "/landing",
    component: _5f735bfa,
    name: "landing"
  }, {
    path: "/login",
    component: _2c924f0c,
    name: "login"
  }, {
    path: "/new-login",
    component: _d630d4c2,
    name: "new-login"
  }, {
    path: "/new-login-2",
    component: _1874c9f8,
    name: "new-login-2"
  }, {
    path: "/register",
    component: _d7244360,
    name: "register"
  }, {
    path: "/reset_password",
    component: _a04b6110,
    name: "reset_password"
  }, {
    path: "/setting",
    component: _3a876bb3,
    name: "setting"
  }, {
    path: "/blogs/new",
    component: _60b08d76,
    name: "blogs-new"
  }, {
    path: "/",
    component: _62eaabf5,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
