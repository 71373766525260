
import UserHeader from '~/components/layouts/UserHeader.vue'
import UserFooter from '~/components/layouts/UserFooter.vue'

export default {
  components: {
    UserHeader,
    UserFooter
  },

  middleware: ['auth'],

  data() {
    return {
      name: ''
    }
  },

  computed: {
    locale() {
      const locale = this.$i18n.locales.find(
        item => item.code === this.$i18n.locale
      )
      const localeISO = locale ? locale.iso : 'en-US'
      const provider = require(`ant-design-vue/es/locale-provider/${localeISO.replace(
        '-',
        '_'
      )}`)
      return provider.default
    }
  },

  mounted() {
    this.setUserName()
    this.checkInfo()
  },

  methods: {
    checkInfo() {
      try {
        if (this.$auth.user.tab_profile !== null) {
          this.$router.push({ path: '/setting' })
        }
      } catch (_) {
        console.warn(_)
      }
    },
    setUserName() {
      const user = this.$auth.user
      if (user && user.first_name) {
        this.name =
          this.formatName(user.first_name) + '' + this.$t('common.after_name')
      }
      this.$store.dispatch('setUserName', this.name)
    },

    formatName(name) {
      if (name.length > 6) {
        return name.slice(0, 6) + '...'
      } else {
        return name
      }
    }
  }
}
