import {
  SET_LOADING,
  SET_IS_PC,
  SET_IS_SIDEBAR_COLLAPSED,
  SET_VISIBLE_USER_OFFCANVAS_MENU,
  SET_DATA_SETTING_PROFILE_CHANGE,
  SET_VISIBLE_SETTING_PROFILE_MODAL,
  SET_USER_NAME
} from '~/constants/mutation-types'

export const state = () => ({
  loading: false,
  isPC: true,
  isSidebarCollapsed: false,
  visibleUserOffcanvasMenu: false,
  dataSettingProfileChange: false,
  visibleSettingProfileModal: false,
  userName: undefined
})

export const getters = {
  loading: state => state.loading,
  isPC: state => state.isPC,
  isSidebarCollapsed: state => state.isSidebarCollapsed,
  visibleUserOffcanvasMenu: state => state.visibleUserOffcanvasMenu,
  dataSettingProfileChange: state => state.dataSettingProfileChange,
  visibleSettingProfileModal: state => state.visibleSettingProfileModal
}

export const mutations = {
  [SET_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [SET_IS_PC]: (state, payload) => {
    state.isPC = payload
  },
  [SET_IS_SIDEBAR_COLLAPSED]: (state, payload) => {
    state.isSidebarCollapsed = payload
  },
  [SET_VISIBLE_USER_OFFCANVAS_MENU]: (state, payload) => {
    state.visibleUserOffcanvasMenu = payload
  },
  [SET_DATA_SETTING_PROFILE_CHANGE]: (state, payload) => {
    state.dataSettingProfileChange = payload
  },
  [SET_VISIBLE_SETTING_PROFILE_MODAL]: (state, payload) => {
    state.visibleSettingProfileModal = payload
  },
  [SET_USER_NAME]: (state, payload) => {
    state.userName = payload
  }
}

export const actions = {
  /**
   * Set loading global
   *
   * @param {Function} commit
   * @param {Boolean} payload
   */
  setLoading({ commit }, payload) {
    commit(SET_LOADING, payload)
  },

  /**
   * Set visible user offcanvas menu
   *
   * @param {Function} commit
   * @param {Boolean} payload
   */
  setVisibleUserOffcanvasMenu({ commit }, payload) {
    commit(SET_VISIBLE_USER_OFFCANVAS_MENU, payload)
  },

  /**
   * Set data setting profile change
   *
   * @param {Function} commit
   * @param {Boolean} payload
   */
  setDataSettingProfileChange({ commit }, payload) {
    commit(SET_DATA_SETTING_PROFILE_CHANGE, payload)
  },

  /**
   * Set visible setting profile modal
   *
   * @param {Function} commit
   * @param {Boolean} payload
   */
  setVisibleSettingProfileModal({ commit }, payload) {
    commit(SET_VISIBLE_SETTING_PROFILE_MODAL, payload)
  },
  setUserName({ commit }, payload) {
    commit(SET_USER_NAME, payload)
  }
}
