export default $axios => ({
  resource: 'intern',
  /**
   * Update resource
   *
   * @param {Number} id
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  update(id, data, config = {}) {
    return $axios.post(`intern-blog/intern/${id}`, data, config)
  },

  /**
   * Update resource
   *
   * @param {Number} id
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  updateBackground(id, data, config = {}) {
    return $axios.put(
      `intern-blog/intern/update-backgrounds/${id}`,
      data,
      config
    )
  },

  /**
   * Update resource
   *
   * @param {Number} id
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  updateWorkingHistory(id, data, config = {}) {
    return $axios.put(
      `intern-blog/intern/update-working-histories/${id}`,
      data,
      config
    )
  },

  /**
   * Get resource
   *
   * @param {Number} id
   * @param {Object} config
   * @returns {Object}
   */
  getBackgrounds(id, config = {}) {
    return $axios.get(`intern-blog/intern/get-backgrounds/${id}`, config)
  },

  /**
   * Get resource
   *
   * @param {Number} id
   * @param {Object} config
   * @returns {Object}
   */
  getHistories(id, config = {}) {
    return $axios.get(`intern-blog/intern/get-working-histories/${id}`, config)
  },

  /**
   * Get User Profile
   */
  getProfile() {
    return $axios.get(`intern-blog/auth/me`)
  },

  /**
   * Forgot password
   *
   * @param {Object} config
   * @returns {Object}
   */
  forgotPassword(config = {}) {
    return $axios.post('intern-blog/forget-password', config)
  },

  /**
   * @param {Object} data
   *
   * @returns {Object}
   */
  resetPassword(data) {
    return $axios.post('intern-blog/reset-password', data)
  }
})
