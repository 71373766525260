
import { mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    currentLocaleISO() {
      const locale = this.$i18n.locales.find(
        item => item.code === this.$i18n.locale
      )
      return locale ? locale.iso : 'ja-JP'
    },
    name() {
      const user = this.$auth.user
      return user && user.username ? user.username : ''
    },
    ...mapState({
      dataSettingProfileChange: 'dataSettingProfileChange',
      visibleSettingProfileModal: 'visibleSettingProfileModal',
      name: 'userName'
    })
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    /**
     * Set locale
     */
    setLocale(code) {
      this.$i18n.setLocale(code)
      location.reload()
    },
    handleScroll() {
      window.onscroll = function () {
        myFunction()
      }

      const header = document.querySelectorAll('.main-user-header')[0]
      const sticky = header.offsetTop

      function myFunction() {
        if (window.pageYOffset > sticky) {
          header.classList.add('active')
        } else {
          header.classList.remove('active')
        }
      }
    },

    /**
     *handle click dropdown
     */
    handleDropdownClick() {
      if (!this.$auth.user) {
        this.$router.push('/login')
      }
    },

    /**
     * Logout user
     */
    async logout() {
      this.$store.dispatch('setLoading', true)

      try {
        if (this.$route.path === '/setting') {
          if (this.dataSettingProfileChange) {
            this.$store.dispatch('setVisibleSettingProfileModal', true)
            return
          }
        }
        await this.$auth.logout()
      } catch (_) {
        this.$notification.error({
          message: this.$t('text.something_wrong')
        })
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    }
  }
}
