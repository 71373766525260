export const ButtonNext = () => import('../../components/button/ButtonNext.vue' /* webpackChunkName: "components/button-next" */).then(c => wrapFunctional(c.default || c))
export const ButtonSubmit = () => import('../../components/button/Submit.vue' /* webpackChunkName: "components/button-submit" */).then(c => wrapFunctional(c.default || c))
export const LayoutsUserFooter = () => import('../../components/layouts/UserFooter.vue' /* webpackChunkName: "components/layouts-user-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsUserHeader = () => import('../../components/layouts/UserHeader.vue' /* webpackChunkName: "components/layouts-user-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsUserOffcanvasMenu = () => import('../../components/layouts/UserOffcanvasMenu.vue' /* webpackChunkName: "components/layouts-user-offcanvas-menu" */).then(c => wrapFunctional(c.default || c))
export const MoleculesSvgIcon = () => import('../../components/molecules/SvgIcon.vue' /* webpackChunkName: "components/molecules-svg-icon" */).then(c => wrapFunctional(c.default || c))
export const OrganismsContactHere = () => import('../../components/organisms/ContactHere.vue' /* webpackChunkName: "components/organisms-contact-here" */).then(c => wrapFunctional(c.default || c))
export const OrganismsModelList = () => import('../../components/organisms/ModelList.vue' /* webpackChunkName: "components/organisms-model-list" */).then(c => wrapFunctional(c.default || c))
export const OrganismsQuickLinks = () => import('../../components/organisms/QuickLinks.vue' /* webpackChunkName: "components/organisms-quick-links" */).then(c => wrapFunctional(c.default || c))
export const ProcessSetting = () => import('../../components/process/processSetting.vue' /* webpackChunkName: "components/process-setting" */).then(c => wrapFunctional(c.default || c))
export const TemplateLandingTop = () => import('../../components/template/landing-top.vue' /* webpackChunkName: "components/template-landing-top" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHomeManufacturer = () => import('../../components/organisms/home/Manufacturer.vue' /* webpackChunkName: "components/organisms-home-manufacturer" */).then(c => wrapFunctional(c.default || c))
export const TemplateBlogsBlogForm = () => import('../../components/template/blogs/BlogForm.vue' /* webpackChunkName: "components/template-blogs-blog-form" */).then(c => wrapFunctional(c.default || c))
export const TemplateSettingConfirmLogoutModal = () => import('../../components/template/setting/ConfirmLogoutModal.vue' /* webpackChunkName: "components/template-setting-confirm-logout-modal" */).then(c => wrapFunctional(c.default || c))
export const TemplateSettingConfirmRedirectModal = () => import('../../components/template/setting/ConfirmRedirectModal.vue' /* webpackChunkName: "components/template-setting-confirm-redirect-modal" */).then(c => wrapFunctional(c.default || c))
export const TemplateSettingConfirmModal = () => import('../../components/template/setting/confirmModal.vue' /* webpackChunkName: "components/template-setting-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const TemplateSettingESignature = () => import('../../components/template/setting/e-signature.vue' /* webpackChunkName: "components/template-setting-e-signature" */).then(c => wrapFunctional(c.default || c))
export const TemplateSettingFilePreviewModal = () => import('../../components/template/setting/filePreviewModal.vue' /* webpackChunkName: "components/template-setting-file-preview-modal" */).then(c => wrapFunctional(c.default || c))
export const TemplateSettingProfile = () => import('../../components/template/setting/profile.vue' /* webpackChunkName: "components/template-setting-profile" */).then(c => wrapFunctional(c.default || c))
export const TemplateSettingProfileUniversity = () => import('../../components/template/setting/profileUniversity.vue' /* webpackChunkName: "components/template-setting-profile-university" */).then(c => wrapFunctional(c.default || c))
export const TemplateSettingWorkHistory = () => import('../../components/template/setting/workHistory.vue' /* webpackChunkName: "components/template-setting-work-history" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
