
export default {
  methods: {
    gotoBlog() {
      if (this.$auth.user) {
        this.$router.push('/blogs')
      } else {
        this.$router.push('/login')
      }
    },

    gotoSetting() {
      if (this.$auth.user) {
        this.$router.push('/setting')
      } else {
        this.$router.push('/login')
      }
    },

    scrollToTop() {
      const scrollDuration = 1000
      const cosParameter = window.scrollY / 2
      let scrollCount = 0
      let oldTimestamp = performance.now()

      function step(newTimestamp) {
        scrollCount +=
          Math.PI / (scrollDuration / (newTimestamp - oldTimestamp))

        if (scrollCount >= Math.PI) {
          window.scrollTo(0, 0)
          return
        }

        if (window.scrollY === 0) return

        window.scrollTo(
          0,
          Math.round(cosParameter + cosParameter * Math.cos(scrollCount))
        )

        oldTimestamp = newTimestamp

        window.requestAnimationFrame(step)
      }

      window.requestAnimationFrame(step)
    }
  }
}
