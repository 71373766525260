import RoleRepository from './role.repository'
import BlogRepository from './blog.repository'
import countryRepository from './country.repository'
import BusinessTypeRepository from './BusinessType.repository'
import internRepository from './intern.repository'
import EmploymentCenter from './employmentCenter.repository'

/**
 * Make repository
 *
 * @param {Object} $axios
 * @param {String} resource
 * @returns {Object}
 */
const make = ($axios, resource) => ({
  resource,

  /**
   * Get list resources
   *
   * @param {Object} config
   * @returns {Object}
   */
  list(config = {}) {
    return $axios.get(`intern-blog/${this.resource}`, config)
  },

  /**
   * Create resource
   *
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  create(data, config = {}) {
    return $axios.post(`intern-blog/${this.resource}`, data, config)
  },

  /**
   * Show detail resource
   *
   * @param {Number} id
   * @param {Object} config
   * @returns {Object}
   */
  show(id, config = {}) {
    return $axios.get(`intern-blog/${this.resource}/${id}`, config)
  },

  /**
   * Update resource
   *
   * @param {Number} id
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  update(id, data, config = {}) {
    return $axios.put(`intern-blog/${this.resource}/${id}`, data, config)
  },

  /**
   * Delete resource
   *
   * @param {Number} id
   * @param {Object} config
   * @returns {Object}
   */
  delete(id, config = {}) {
    return $axios.delete(`intern-blog/${this.resource}/${id}`, config)
  }
})

/**
 * Binding repository
 *
 * @param {Object} $axios
 * @param {Functiion} createRepository
 * @returns {Object}
 */
const bind = ($axios, createRepository) => {
  const repository = createRepository($axios)
  if (!repository.resource) {
    throw new Error('Missing resource')
  }

  return {
    ...make($axios, repository.resource),
    ...repository
  }
}

export default $axios => ({
  role: bind($axios, RoleRepository),
  user: make($axios, 'user'),
  intern: bind($axios, internRepository),
  blog: bind($axios, BlogRepository),
  country: bind($axios, countryRepository),
  businessType: bind($axios, BusinessTypeRepository),
  employmentCenter: bind($axios, EmploymentCenter)
})
