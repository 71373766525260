export const SET_LOADING = 'SET_LOADING'
export const SET_IS_PC = 'SET_IS_PC'
export const SET_IS_SIDEBAR_COLLAPSED = 'SET_IS_SIDEBAR_COLLAPSED'

export const SET_VISIBLE_USER_OFFCANVAS_MENU = 'SET_VISIBLE_USER_OFFCANVAS_MENU'
export const SET_DATA_SETTING_PROFILE_CHANGE = 'SET_DATA_SETTING_PROFILE_CHANGE'
export const SET_VISIBLE_SETTING_PROFILE_MODAL =
  'SET_VISIBLE_SETTING_PROFILE_MODAL'

export const SET_USER_NAME = 'SET_USER_NAME'
